<template>
  <v-container fluid>
    <AError :api="apiUpdateAttachment" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.name.plural }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                class="hidden-xs-only"
                @click="toggleUpdateAtchDialog(true)"
              >
                <v-icon dark v-bind="attrs" v-on="on"> mdi-upload </v-icon>
              </v-btn>
            </template>
            <span>Update {{ model.name.plural }}</span>
          </v-tooltip>
        </span>
      </v-toolbar>

      <div class="pa-3">
        <UploadAttachmentSettingFileSizeAlert></UploadAttachmentSettingFileSizeAlert>

        <v-expansion-panels v-model="panel" multiple focusable>
          <v-row>
            <v-col cols="6" sm="12" md="9" lg="6">
              <v-expansion-panel
                v-for="(item, key) in staticTaxpodPricing"
                :key="key"
              >
                <v-expansion-panel-header color="grey lighten-2">
                  <span class="text-h6">
                    {{ item }}
                  </span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="pt-3">
                    <v-col cols="9" class="pb-0">
                      <div
                        v-cloak
                        @drop.prevent="addDropFile($event, 'guideline', true)"
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          label="Guildeline"
                          accept=".pdf"
                          v-model="formData[item].guideline"
                          :error-messages="errorField[item].guideline"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>
                    <v-col cols="3" class="pb-0">
                      <v-btn
                        x-large
                        :color="HRDC_BLUE_THEME_COLOR"
                        outlined
                        @click="previewAttachment(formData[item].guideline)"
                      >
                        Preview
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="my1-0">
                    <v-col cols="9" class="py-0">
                      <div
                        v-cloak
                        @drop.prevent="addDropFile($event, 'flyer', true)"
                        @dragover.prevent
                        class="w-100"
                      >
                        <v-file-input
                          :prepend-icon="null"
                          prepend-inner-icon="mdi-paperclip"
                          dense
                          filled
                          small-chips
                          label="Flyer"
                          accept=".pdf"
                          v-model="formData[item].flyer"
                          :error-messages="errorField[item].flyer"
                          :truncate-length="1000"
                        ></v-file-input>
                      </div>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-btn
                        x-large
                        :color="HRDC_BLUE_THEME_COLOR"
                        outlined
                        @click="previewAttachment(formData[item].flyer)"
                      >
                        Preview
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-col>
          </v-row>
        </v-expansion-panels>
      </div>
    </v-card>

    <TheModalConfirmation
      ref="updateAtchConfirmPopup"
      @agree="agreeCallbacUpdateAtch()"
      @disagree="disagreeCallbacUpdateAtch()"
    ></TheModalConfirmation>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import TheModalConfirmation from "@/components/common/TheModalConfirmation.vue";
import UploadAttachmentSettingFileSizeAlert from "@/components/views/UploadAttachmentSettingFileSizeAlert.vue";

export default {
  components: {
    TheModalConfirmation,
    UploadAttachmentSettingFileSizeAlert,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiUpdateAttachment: new Api(),
    apiGetAttachmentBlob: new Api(),
    model: new Model(),
    service: new Service(),
    alertError: [],
    formData: null,
    errorField: null,
    loadingDialog: false,
    panel: null,
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.serviceDataAssignation(this.service.key);

    const apiDetails = {
      apiGetAttachmentBlob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-blob`,
      },
      apiUpdateAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/attachment/update-attachment`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    // Open all expansion panel tabs.
    this.panel = this.staticTaxpodPricing.map((_, index) => index);

    // Assign fake pdf data.
    for (const price of this.staticTaxpodPricing) {
      this.formData = {
        ...this.formData,
        [price]: {
          guideline: {
            name: `${this.guidelineName}${price}.pdf`,
          },
          flyer: {
            name: `${this.flyerName}${price}.pdf`,
          },
        },
      };

      this.errorField = {
        ...this.errorField,
        [price]: {
          guideline: null,
          flyer: null,
        },
      };
    }

    this.rolesAssignation(this.auth.roles);
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error].guideline = null;
        this.errorField[error].flyer = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    redirectBack() {
      let route = this.model.parent_route;
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    addDropFile(e, key, strictPdf = true) {
      let file = e.dataTransfer.files[0];

      if (strictPdf) {
        if (!file.type.match("pdf.*")) {
          this.errorField[key] = "Please select pdf file format";
          return;
        }
      }

      this.formData[key] = file;
    },
    previewAttachment(fileType) {
      // Preview for static file.
      // Check is empty file.
      if (!fileType) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
          timeout: 2000,
        });
        return false;
      }

      this.showLoadingDialog();

      const fileUrl = `assets/storage/${fileType.name}`;

      this.$axios
        .get(this.apiGetAttachmentBlob.url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
          },
        })
        .then((response) => {
          const blobUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(blobUrl, "_blank");
        })
        .catch(() => {
          this.previewBlobAttachment(fileType);
        })
        .finally(() => {
          this.hideLoadingDialog();
        });
    },
    async previewBlobAttachment(fileType) {
      // Preview for newly attached file.
      this.showLoadingDialog();

      if (!fileType) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      switch (fileType) {
        default:
          url = URL.createObjectURL(fileType);
          newWindow = window.open(url, "_blank");
          break;
      }

      if (newWindow) {
        newWindow.onload = function () {
          // Revoke the object URL when the window is closed
          newWindow.addEventListener("beforeunload", function () {
            URL.revokeObjectURL(url);
          });
        };
      }
    },
    getOriFileName(price, file) {
      let fileName = null;

      switch (file) {
        case "guideline":
          fileName = `${this.guidelineName}${price}.pdf`;
          break;

        case "flyer":
          fileName = `${this.flyerName}${price}.pdf`;
          break;
      }

      return fileName;
    },
    toggleUpdateAtchDialog(val) {
      if (val) {
        this.$refs.updateAtchConfirmPopup.open({
          title: "Update Attachment",
          message: "Are you confirm to update attachment?",
          options: {
            btnDisagree: "Cancel",
            btnAgree: "Update",
          },
          agree: () => {
            this.agreeCallbacUpdateAtch();
          },
          disagree: () => {
            this.disagreeCallbacUpdateAtch();
          },
        });
      }
    },
    agreeCallbacUpdateAtch() {
      this.showLoadingDialog();
      this.clearErrorMsg();

      // Check is file fields empty.
      let isUploadContainsEmpty = false;

      Object.entries(this.formData).forEach(([field, value]) => {
        this.staticTaxpodDocs.forEach((key) => {
          if (!value[key]) {
            this.errorField[field][key] = `${this.capitalizeFirstLetter(
              key
            )} ${field} is required`;
            isUploadContainsEmpty = true;
          }
        });
      });

      if (isUploadContainsEmpty) {
        this.hideLoadingDialog();
        return false;
      }

      const fd = new FormData();

      // Only append File instance.
      Object.entries(this.formData).forEach(([atch, data]) => {
        this.staticTaxpodDocs.forEach((key) => {
          if (data[key] instanceof File) {
            fd.append(
              `${atch}_${key}_filepath`,
              `assets/storage/${this.getOriFileName(atch, key)}`
            );
            fd.append(`${atch}_${key}`, data[key]);
          }
        });
      });

      const fdLength = [...fd.entries()].length;
      if (fdLength == 0) {
        this.$store.dispatch("showMessage", {
          message: "No changes apply!",
          messageType: "info",
          timeout: 2000,
        });

        this.hideLoadingDialog();
        return;
      }

      // Only append static datas after checking.
      fd.append("staticFiles", this.staticTaxpodDocs);
      fd.append("staticPrice", this.staticTaxpodPricing);

      this.apiUpdateAttachment.setParams(fd);
      this.apiUpdateAttachment.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;

          let messageType = "success";
          if (!status) {
            messageType = "errror";
          }

          this.$store.dispatch("showMessage", {
            message: data,
            messageType,
            timeout: 2000,
          });
        } catch (err) {
          console.log(err);
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiUpdateAttachment.fetch();
    },
    disagreeCallbacUpdateAtch() {
      //
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0 10px 16px;
}
</style>
